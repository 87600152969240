<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    header="CONFIGURACIÓN DE WHATSAPP"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="data_whatsapp.sucursal_id"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione una sucursal..."
          />
        </div>
        <div class="field col-12 md:col-6">
          <span
            ><strong>
              Cu&aacute;ntas horas antes enviar el recordatorio?</strong
            >
          </span>

          <i class="pi pi-whatsapp"></i>
          <Dropdown
            v-model="horas_selected"
            :options="horas_antes"
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>NOMBRE TEMPLATE: </strong></span>
          <InputText
            v-model="data_whatsapp.template_name"
            placeholder="Nombre del Template"
          />
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>WEBHOOK URL: </strong></span>
          <Textarea
            v-model="data_whatsapp.webhook_url"
            placeholder="URL del Webhook"
            rows="1"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_whatsapp.estado"
            :disabled="!data_whatsapp.id"
            :options="estados"
            optionLabel="label"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>WEBHOOK TOKEN: </strong></span>
          <Textarea
            v-model="data_whatsapp.webhook_token"
            rows="1"
            autoResize
            placeholder="Token del Webhook"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_whatsapp.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_whatsapp.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="saveFormulario"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import WhatsAppService from "@/service/WhatsAppService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    whatsappnew: {
      type: Object,
      default() {
        return {};
      },
    },
    sucursales: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  whatsappService: null,
  rolService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_whatsapp: this.whatsappnew,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      enviandoWhatsapp: false,
      horas_selected: null,
      horas_antes: [
        { label: "Al registrar la cita", value: 0 },
        { label: "4 horas antes", value: 4 },
        { label: "12 horas antes (medio día)", value: 12 },
      ],
    };
  },
  created() {
    this.whatsappService = new WhatsAppService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    saveFormulario() {
      this.errors = {};
      this.enviado = true;
      if (this.data_whatsapp.id) {
        let datos = {
          ...this.data_whatsapp,
          estado: this.data_whatsapp.estado.value,
          horas_antes: this.horas_selected,
        };
        this.whatsappService.updateFrm(datos).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            if (data.success === false) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_whatsapp;
        this.data_whatsapp = {
          ...this.data_whatsapp,
          estado: this.data_whatsapp.estado.value,
          horas_antes: this.horas_selected,
        };
        this.whatsappService.sendFrmNew(this.data_whatsapp).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_whatsapp = tmp;
          } else {
            this.errors = {};
            if (data.success === false) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 10000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    whatsappnew(val) {
      this.data_whatsapp = val;
      if (this.data_whatsapp.horas_antes) {
        let hora = this.data_whatsapp.horas_antes.split(":");
        this.horas_selected = parseInt(hora[0]);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
